<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <AppDialog :value="value" @close="closeDialog">
        <AppCard class="pa-6">
          <v-form ref="form">
            <v-layout wrap justify-center>
              <v-flex xs12 text-right>
                <v-icon @click="closeDialog">mdi-close</v-icon>
              </v-flex>
              <v-flex xs12 text-center class="card-title small">
                Richiedi Servizi
              </v-flex>
              <v-flex xs12 pt-5 px-4>
                <v-layout wrap justify-start>
                  <v-flex xs12 md6 px-4 pb-2>
                    <v-text-field
                      v-model="name"
                      placeholder="Nome"
                      hide-details
                      outlined
                      dense
                      :rules="nameRules"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6 px-4 pb-2>
                    <v-text-field
                      v-model="phone"
                      placeholder="Telefono"
                      outlined
                      hide-details
                      dense
                      :rules="phoneRules"></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4>
                    <v-text-field
                      v-model="email"
                      placeholder="Email"
                      hide-details
                      outlined
                      dense
                      :rules="emailRules"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 text-center pt-2 class="error--text">
                {{ message }}
              </v-flex>
              <v-flex xs12 text-center pt-2>
                <v-btn
                  color="primary"
                  dark
                  :ripple="false"
                  depressed
                  @click="register">
                  <span class="text-capitalize">Invia</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </AppCard>
      </AppDialog>
    </v-flex>
    <v-snackbar
      v-model="success"
      :bottom="true"
      :right="true"
      :timeout="2500"
      color="success">
      Richiesta inviata con successo!
    </v-snackbar>
  </v-layout>
</template>

<script>
import AppDialog from "@/components/shared/AppDialog";
import AppCard from "@/components/shared/AppCard";

export default {
  name: "Register",
  components: {
    AppDialog,
    AppCard,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      email: null,
      name: null,
      phone: null,
      message: null,
      success: false,
      nameRules: [
        (value) => {
          if (value) return true;
          return false;
        },
      ],
      phoneRules: [
        (value) => {
          if (value?.length > 7 && /[0-9-]+/.test(value)) return true;
          return false;
        },
      ],
      emailRules: [
        (value) => {
          if (value) return true;
          return false;
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;
          return false;
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.email = null;
      this.name = null;
      this.phone = null;
      this.message = null;
      if (this.message) this.message = null;
      this.$emit("close");
    },
    async register() {
      if (!this.$refs.form.validate()) return;
      const payload = { email: this.email, name: this.name, phone: this.phone };
      this.$store
        .dispatch("createServiceRequest", payload)
        .then(() => {
          this.loading = false;
          this.success = true;
          this.closeDialog();
        })
        .catch((err) => {
          console.error(err);
          if (err.data.email) {
            this.message = err.data.email.join(", ");
          } else if (err.data.non_field_errors) {
            this.message = err.data.non_field_errors.join(", ");
          } else {
            this.message = err.message;
          }
        });
    },
  },
};
</script>
