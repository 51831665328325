/* | =================================================== | This File is the
footer for the app | =================================================== */
<template>
  <v-footer class="app-footer">
    <v-layout wrap justify-center pa-4 pa-md-12>
      <v-flex xs12 md11 lg9 offset-sm-1 py-6>
        <v-layout wrap justify-start>
          <v-flex xs12 sm5 md5 lg4>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <div class="heading">Consulbrokers S.P.A.</div>
              </v-flex>
              <v-flex xs12 pt-4>
                <div class="description">
                  C.so di Porta Nuova, 16, Milano, 20121
                  <br />
                  <a href="mailto:info@cbdigital.tech"></a>
                  info@cbdigital.tech Tel. +30 02.70102458
                  <br />
                  <br />
                  Consulbrokers Digital Srl,
                  <br />
                  società di CB Consulbrolkers Spa
                  <br />
                  Via Mergellina 2 - 80122 Napoli
                  <br />
                  Corso di Porta Nuova 16 - 20121 Milano
                  <br />
                  P.IVA / C.F: 01209750379
                  <br />
                  Società sottoposta alla vigilanza IVASS
                  <br />
                  Iscrizione Rui: B000180506
                  <br />
                  PEC:
                  <a href="mailto:cbdigital@pec-legal.it">
                    cbdigital@pec-legal.it
                  </a>
                  <br />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm7 lg8>
            <v-layout wrap>
              <v-flex xs6 sm3 md3 pt-5 pt-sm-0>
                <v-flex xs12>
                  <div class="heading small">Mappa sito</div>
                </v-flex>
                <v-flex xs12 pt-4>
                  <div class="description underline">
                    <router-link
                      :to="{ name: 'home' }"
                      tag="span"
                      class="links">
                      Home
                    </router-link>
                    <br />
                    <br />
                    <router-link
                      :to="{ name: 'products' }"
                      tag="span"
                      class="links">
                      Prodotti
                    </router-link>
                    <br />
                    <br />
                    <router-link
                      :to="{ name: 'conventions' }"
                      tag="span"
                      class="links">
                      Convenzioni
                    </router-link>
                  </div>
                </v-flex>
              </v-flex>
              <v-flex xs6 sm2 md2 lg3 pt-5 pt-sm-0>
                <v-flex xs12>
                  <div class="heading small">Policies</div>
                </v-flex>
                <v-flex xs12 pt-4>
                  <div class="description underline">
                    <a
                      href="http://www.consulbrokers.it/azienda/privacy-policy"
                      target="_blank">
                      Privacy policy
                    </a>
                    <br />
                    <br />
                    <a
                      href="https://www.consulbrokers.it/gestione-reclami.pdf"
                      target="_blank">
                      Gestione reclami
                    </a>
                    <br />
                    <br />
                    <a href="RNA-CBDigital.xltx" target="_blank">
                      Rna Consulbrokers
                    </a>
                  </div>
                </v-flex>
              </v-flex>
              <v-flex xs6 sm2 md2 lg2 pt-5 pt-sm-0>
                <v-flex xs12>
                  <div class="heading small">Links</div>
                </v-flex>
                <v-flex xs12 pt-4>
                  <div class="description">
                    <v-icon color="white">mdi-linkedin</v-icon>
                    <span class="underline">
                      <a
                        href="https://www.linkedin.com/company/consulbrokers-digital/"
                        target="_blank">
                        LinkedIn
                      </a>
                    </span>
                  </div>
                </v-flex>
              </v-flex>
              <v-flex xs12 pt-4 class="description">
                Qualora sussista una doglianza attinente al comportamento
                dell’intermediario, vi è la facoltà di inoltrare reclamo per
                iscritto ad Consulbrokers Digital S.r.l, Ufficio Reclami Via
                Mergellina 2 CAP 80122, Napoli oppure a mezzo e-mail,
                specificando nell'oggetto "RECLAMO", all'indirizzo:
                <a href="mailto:cbdigital@pec-legal.it">
                  cbdigital@pec-legal.it
                </a>
                .
                <br />
                La normativa concede 45 giorni entro i quali fornire una
                risposta
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md2 py-6 text-center>
        <v-btn
          color="primary"
          dark
          :ripple="false"
          depressed
          href="mailto:info@cbdigital.tech">
          <span class="text-capitalize">Contattaci</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style>
.links {
  cursor: pointer;
}
</style>
