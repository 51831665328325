<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <AppDialog :value="value" @close="closeDialog">
        <AppCard class="pa-6">
          <v-layout wrap justify-center>
            <v-flex xs12 text-right>
              <v-icon @click="closeDialog">mdi-close</v-icon>
            </v-flex>
            <v-flex xs12 text-center class="card-title small"> Accesso </v-flex>
            <!--<v-flex xs6 text-center>
              <v-radio-group v-model="loginType" row>
                <v-radio label="Retail" value="retail"></v-radio>
                <v-radio label="Cauzioni" value="cazioni"></v-radio>
              </v-radio-group>
            </v-flex>-->
            <v-flex xs12 pt-5 px-4 v-if="showInput">
              <v-layout wrap justify-start>
                <v-flex xs12 px-4 pb-2>
                  <v-text-field
                    v-model="email"
                    placeholder="Email"
                    outlined
                    hide-details
                    dense></v-text-field>
                </v-flex>
                <v-flex xs12 px-4>
                  <v-text-field
                    v-model="password"
                    type="password"
                    placeholder="Password"
                    hide-details
                    outlined
                    dense></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 text-center pt-2 class="error--text">
              {{ message }}
            </v-flex>
            <v-flex xs12 text-center pt-2>
              <v-btn
                color="primary"
                dark
                :ripple="false"
                depressed
                v-if="showInput"
                @click="authenticate">
                <span class="text-capitalize">Accedi</span>
              </v-btn>
              <v-btn
                color="primary"
                dark
                :ripple="false"
                depressed
                v-else
                @click="closeDialog"
                :to="{ name: 'home' }">
                <span class="text-capitalize">Accedi</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </AppCard>
      </AppDialog>
    </v-flex>
  </v-layout>
</template>

<script>
import AppDialog from "@/components/shared/AppDialog";
import AppCard from "@/components/shared/AppCard";

export default {
  name: "Login",
  components: {
    AppDialog,
    AppCard,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loginType: "retail",
      email: null,
      password: null,
      notification: false,
      message: null,
    };
  },
  computed: {
    showInput() {
      return this.loginType === "retail";
    },
  },
  methods: {
    closeDialog() {
      this.email = null;
      this.password = null;
      this.loginType = "retail";
      if (this.message) this.message = null;
      this.$emit("close");
    },
    authenticate() {
      const payload = { email: this.email, password: this.password };
      this.$store
        .dispatch("authenticate", payload)
        .then((res) => {
          this.$store.dispatch("getProfile").then(() => {
            this.closeDialog();
            this.$emit("success");
          });
        })
        .catch((err) => {
          if (err.data.email) {
            this.message = err.data.email.join(", ");
          } else if (err.data.non_field_errors) {
            this.message = err.data.non_field_errors.join(", ");
          } else {
            this.message = err.message;
          }
        });
    },
  },
};
</script>
