<template>
  <v-layout full-height wrap justify-center>
    <v-flex xs12>
      <AppHeader />
    </v-flex>
    <v-flex ref="pageContent" xs12 id="page-content">
      <router-view></router-view>
    </v-flex>
    <v-flex xs12>
      <AppFooter />
    </v-flex>
  </v-layout>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader";
import AppFooter from "@/components/shared/AppFooter";

export default {
  name: "BaseLayout",
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
